/**
 * This is the entry point of an application.
 * See `@commercetools-frontend/application-shell` for usage.
 */
import { StrictMode, lazy } from 'react';
import { ThemeProvider } from '@commercetools-uikit/design-system';
import memoize from 'memoize-one';
import {
  ApplicationShellProvider,
  SetupFlopFlipProvider,
} from '@commercetools-frontend/application-shell';
import { useApplicationContext } from '@commercetools-frontend/application-shell-connectors';
import {
  useCookieConsent,
  CookieConsentBanner,
  useSkipCookieConsent,
} from '@commercetools-frontend/cookie-consent/react';
import {
  useTrackingEffect as useFullStoryTrackingEffect,
  type TFullStoryProperties,
} from '@commercetools-frontend/fullstory';
import {
  ALL_DEFAULT_FLAGS,
  DEFAULT_SHORT_LIVED_FLAGS,
} from '../../constants/feature-toggles';
import applicationMessages from '../../messages';
import WrapWithIntlProviderIfNeeded from '../wrap-with-intl-provider-if-needed';
import GlobalStyles from './global-styles';

const AsyncAuthenticationRoutes = lazy(
  () => import('../../routes' /* webpackChunkName: "authentication" */)
);

export const AuthenticationCookieConsentBanner = () => {
  const skipCookieConsent = useSkipCookieConsent();

  return (
    <WrapWithIntlProviderIfNeeded isAuthenticated>
      <CookieConsentBanner skipConsent={skipCookieConsent} />
    </WrapWithIntlProviderIfNeeded>
  );
};

type TAdditionalEnvironmentValues = {
  cloudEnvironment: string;
};

type ApplicationAuthenticationProps = {
  children: React.ReactNode;
};
export const ApplicationAuthentication = (
  props: ApplicationAuthenticationProps
) => {
  const { givenConsent } = useCookieConsent('performanceCookies');

  const fullStoryAppContextProperties = useApplicationContext<
    TFullStoryProperties,
    TAdditionalEnvironmentValues
  >((context) => ({
    userId: context.user?.id,
    userLocale: context.user?.locale,
    userBusinessRole: context.user?.businessRole,
    projectKey: context.project?.key,
    isUserAdminOfCurrentProject: context.project?.isUserAdminOfCurrentProject,
    environment: context.environment.cloudEnvironment,
    organizationId: context.project?.ownerId,
    isProductionProject: context.project?.isProductionProject,
  }));

  useFullStoryTrackingEffect({
    properties: fullStoryAppContextProperties,
    disable: !givenConsent,
  });

  return <>{props.children}</>;
};
ApplicationAuthentication.displayName = 'ApplicationAuthentication';

const createFlopflipUser = memoize((cloudEnvironment) => ({
  launchdarklyTrackingCloudEnvironment: cloudEnvironment,
}));

// We don't want and need to use OIDC workflow when testing
// the login locally.
delete window.app.__DEVELOPMENT__;

const EntryPoint = () => (
  <StrictMode>
    {/* {See this line in the AppKit DialogContainer source:
    https://github.com/commercetools/merchant-center-application-kit/blob/main/packages/application-components/src/components/dialogs/internals/dialog-container.tsx#LL13C14-L13C14

    Because we are _not_ rendering AppShell, which would expose a target div for modal content,
    we must stub it here for the cookie consent banner component
    } */}
    <div id="portals-container">
      <GlobalStyles />
      <ApplicationShellProvider
        environment={window.app}
        applicationMessages={applicationMessages}
      >
        {({ isAuthenticated }) => (
          <SetupFlopFlipProvider
            ldClientSideId={window.app.ldClientSideId || undefined}
            appEnv={window.app.env}
            user={createFlopflipUser(window.app.cloudEnvironment)}
            flags={DEFAULT_SHORT_LIVED_FLAGS}
            defaultFlags={ALL_DEFAULT_FLAGS}
            shouldDeferAdapterConfiguration={false}
          >
            <>
              <ThemeProvider />
              <AuthenticationCookieConsentBanner />
              <ApplicationAuthentication>
                <AsyncAuthenticationRoutes isAuthenticated={isAuthenticated} />
              </ApplicationAuthentication>
            </>
          </SetupFlopFlipProvider>
        )}
      </ApplicationShellProvider>
    </div>
  </StrictMode>
);
EntryPoint.displayName = 'EntryPoint';

export default EntryPoint;
